<template>
  <div class="footer__social-icons">
    <template v-for="entry in soMeEntries">
      <a v-if="entry.link" :href="entry.link" target="_blank">
        <FontAwesomeIcon :icon="entry.icon" />
        <span>{{ entry.type }}</span>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object],
    },
  },
  computed: {
    soMeEntries() {
      return [
        {
          type: 'Facebook',
          icon: ['fab', 'facebook'],
          link: this.value.facebook_url,
        },
        {
          type: 'Instagram',
          icon: ['fab', 'instagram'],
          link: this.value.instagram_url,
        },
        {
          type: 'Linkedin',
          icon: ['fab', 'linkedin'],
          link: this.value.linkedin_url,
        },
        {
          type: 'Twitter',
          icon: ['fab', 'twitter'],
          link: this.value.twitter_url,
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.footer__social-icons {
  color: inherit;
  display: flex;
  flex-wrap: wrap;
  gap: .25rem;
  
  @include respond-below('phone') {
    flex-direction: column;
  }

  a {
    display: inline-flex;
    gap: .75rem;
    align-items: center;
  }
}
</style>
