<template>
  <div class="contact-portal">
    <div class="contact-portal__top">
      <strong class="contact-portal__name">{{ item.name }}</strong>
      <span class="contact-portal__title">{{ item.title }}</span>
    </div>
    <div class="contact-portal__bottom">
      <a class="footer__contact-email" :href="`mailto:${item.email}`">{{ item.email }}</a>
      <a class="footer__contact-phone" :href="`tel:${(item.phone || '').trim()}`">
        {{ item.phone }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'cards',
      enums: ['cards', 'masonry'],
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.contact-portal {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1.1rem;

  &__name {
    font-size: 1.2rem;
  }
  &__top {
    margin-top: 2rem;
    h3 {
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    a {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    a {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
    }
  }
}
</style>
