<template>
  <div id="app">
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        { 'nav--expanded': isExpanded },
        { 'nav-is-hidden': !showNavbar && !isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :height="32"
          :items="items"
          :title="projectName"
          padding="1.5rem 0"
          :action="redirect(['Page'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
          ref="nav"
        />
      </div>
    </div>
    <main class="app-content">
      <transition mode="out-in" name="route">
        <router-view />
      </transition>
    </main>
    <FooterComponent
      v-if="$route.meta.footer !== false"
      :navigation="items.filter(i => i.id !== 'social-media')"
      :cookie-manager="CM"
    />
    <AssetComponent />
  </div>
</template>

<script>
import FooterComponent from '@/components/Footer'
import loadCM from '@/gdpr'
import BrowserApiMixin from '@/mixins/browser-api'
import AssetComponent from '@kvass/asset-manager'
import { NavigationComponent } from 'vue-elder-navigation'
import { mapActions, mapState } from 'vuex'
import API from './api'
import { eventBus } from '@/components/Page/Logo'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      showNavbar: true,
      CM: {},
      observer: null,
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return this.logos.light
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },

    projectName() {
      return this.item.name || ''
    },

    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },

    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },

    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },

    defaultItems() {
      return [
        ...this.posts.map(i => {
          if (!this.$path('customFields.page-settings.display-in-menu', i)) return {}
          return {
            label: i.title,
            exact: true,
            action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
            order: (this.$path('customFields.page-settings.navigation-order', i) || 1) * 10 - 5,
            class: 'navigation-page',
            id: 'navigation-page',
          }
        }),

        {
          label: this.$t('getInTouch'),
          class: 'elder__navigation-component--primary',
          action: this.redirect('Contact'),
          order: 100,
          id: 'contact',
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
    onObserve(entries) {
      const rect = entries[0].contentRect
      document.documentElement.style.setProperty('--nav-height', `${rect.height}px`)
    },
  },
  created() {
    this.promise = this.fetch().then(() => (this.CM = loadCM(this)))
    this.observer = new ResizeObserver(this.onObserve)
  },
  mounted() {
    this.observer.observe(this.$refs.nav.$el)

    eventBus.on('logo:playing', item => {
      document.querySelector('html').style = 'height: 100vh; overflow: hidden;'
    })

    eventBus.on('logo:completed', item => {
      document.querySelector('html').style = 'height: 100%; overflow: unset;'
    })
  },
  destroyed() {
    this.observer.disconnect()
    this.observer = null
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

html {
  @include respond-above('desktop') {
    @media (min-height: 750px) {
      scroll-snap-type: y proximity;
      scroll-padding-top: var(--nav-height, 5rem);
    }
  }
}

// To minimize layout shift while the page is loading we make the main content area grow,
// so the footer will be pushed to the bottom.
#app {
  // height: 100%;
  display: flex;
  flex-direction: column;

  .app-content {
    flex-grow: 1;
  }
}

.nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: var(--primary);
  color: var(--primary-contrast);
  border-bottom: 1px solid currentColor;

  &--expanded {
    height: 100vh;

    .container {
      padding: 0;
    }

    .elder__navigation-node {
      margin-left: 2rem;
    }

    .elder__navigation-bars {
      margin-right: 2rem;
    }

    .elder__navigation-actions {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;

      .elder__navigation-node {
        padding: 0.5rem;
      }
    }

    .elder__navigation-actions > * {
      margin-left: 0rem;
      flex-grow: 1;
      width: 100%;
    }
  }
}
</style>
