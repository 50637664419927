<template>
  <Loader :value="promises.fetch" theme="default" class="page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import BrowserApiMixin from '@/mixins/browser-api'
import { mapActions, mapState } from 'vuex'

// pagebuilder
import config from '@/config/page'
import { Blocks } from '@kvass/pagebuilder'

// custom components
import Page from '@/components/Page'
import Portal from '@/components/Portal'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Page', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { ...Page, Portal }
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.page {
  .hero {
    $height: 70vh;
    $resposiveHeight: 70vh;

    height: $height;

    @include respond-below('phone') {
      height: $resposiveHeight;
    }

    &__grid {
      position: relative;

      @include respond-below('tablet') {
        grid-template-columns: var(--kpb-grid-template-columns) !important;
      }
    }


    &__slider {
      border: 1px solid currentColor;
      grid-column: 2 / span 1 !important;

      .kpb-slider__slider {
        height: $height;

        @include respond-below('phone') {
          height: $resposiveHeight;
        }
      }

      iframe {
        min-height: $height;
      }
    }

    &__content {
      border: none !important;

      position: absolute;
      inset: 0;
      z-index: get-layer('cta');

      .kpb-text {
        max-width: 500px;
      }

      @media (max-height: 800px) {
        --section-padding-block: 2rem;
      }

      .kpb-section__container {
        position: relative;
        height: 100%;
        // display: grid;
        // grid-template-rows: auto auto 1fr;
      }

    }
  }

  .kpb-section {
    border-top: 1px solid currentColor;
  }

  .project-portal {
    &-wrapper {
      @include respond-below('phone') {
        align-items: flex-start;
        gap: 0.5rem;
      }
    }

    &-button {
      border-color: var(--brand-primary);
      border-width: 2px;
    }
  }
}
</style>
