<template>
  <div class="project-card" :class="`project-card--${theme}`">
    <template v-if="theme === 'masonry'">
      <span class="project-card__name">{{ item.name }}</span>
    </template>
    <template v-else-if="theme === 'tiles'">
      <span v-if="status" class="project-card__status">{{ status }}</span>
      <div class="project-card__content">
        <h3 class="project-card__name">{{ item.name }}</h3>

        <span v-if="$path('address.city', item)" class="project-card__address">
          {{ item.address.city }}
        </span>

        <span class="project-card__view-project">
          {{ $t('viewProject') }}
        </span>
      </div>
    </template>

    <template v-else>
      <div class="project-card__content">
        <h3 class="project-card__title">{{ item.name }}</h3>
        <div v-html="$path('customFields.project-excerpt', item)"></div>
        <p class="project-card__link">Les mer om prosjektet</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'cards',
      enums: ['cards', 'masonry', 'tiles'],
    },
  },
  computed: {
    status() {
      let saleTypes = this.$path('item.metadata.residentialProperties.saleType') || []
      if (saleTypes.includes('rent')) return this.$t(`residentialStatus:rent.${this.item.status}`)

      return this.$t(`residentialStatus:sale.${this.item.status}`)
    },
    housingTypes() {
      let types = this.item?.metadata?.residentialProperties
      if (!types?.propertyType) return
      return types.propertyType
        .map(type => {
          let propertyType = 'propertyType:' + type

          /*
          liten potensiel bug,
          hvis de har feks bolig til 200000kvm og kontor til 100000kvm
          så vises bolig til 200000kvm på kontor.
          */
          if (type === 'office') {
            if (Math.max(types.useableArea))
              return this.$tc(propertyType) + ' - ' + Math.max(types.useableArea) + ' kvm'
          }

          return this.$tc(propertyType)
        })
        .splice(0, 4)
        .join(' | ')
    },
  },
}
</script>

<style lang="scss">
.project-card {
  $breakAt: 850px;

  &--masonry {
    .project-card__name {
      font-size: 0.9em;
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      padding: 0.5rem 0.8rem;
      background: rgba(black, 0.6);
      color: white;
      border-radius: 5px;
      transition: opacity 200ms ease-out;
      pointer-events: none;
    }
  }

  &--card {
    .project-card {
      &__title {
        font-size: 1.2rem;
        line-height: 1.2;
        font-weight: normal;
        margin-top: 2rem;
      }

      &__content {
        color: var(--primary-contrast);

        svg {
          font-size: 0.7em;
          margin-right: 0.3em;
        }
      }

      &__address {
        text-transform: uppercase;
        font-size: 0.6em;

        svg {
          margin-right: 0.5em;
          margin-left: 0.1em;
          font-size: 1.2em;
        }
      }

      &__link {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 3px;

        &::before {
          content: '';
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCA0NSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00MS4zNTk3IDE3TDM3IDEyLjQxOEwzOC4zMzk3IDExTDQ1IDE4TDM4LjMzOTcgMjVMMzcgMjMuNTgyTDQxLjM1OTcgMTlIMFYwSDEuOTM4MzNWMTdINDEuMzU5N1oiIGZpbGw9IiNFQ0VBRTgiLz4KPC9zdmc+Cg==');
          background-size: contain;
          background-repeat: no-repeat;

          height: 1.2em;
          aspect-ratio: 2/1;
          font-weight: light;
          display: inline-block;
          text-decoration: none;
          margin-right: .5rem;
        }
      }
    }
  }

  &--tiles {
    .project-card {
      &__status {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 11;
        color: white;
        background: var(--primary);
        font-size: 0.8em;
        text-align: center;
        padding: 0.75rem;
      }

      &__content {
        color: white;
        font-size: 1.5rem;

        position: absolute;
        inset: 0;
        z-index: 10;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.4);
        transition: background 0.2s ease;
        display: grid;
        gap: 1rem;
        place-content: center;
        text-align: center;
      }

      &__name {
        margin: 0;
      }

      &__address {
        margin: 0;
        font-size: 0.7em;
        margin-bottom: 1rem;
      }

      &__view-project {
        margin: 0;
        font-size: 0.7em;
        text-decoration: underline;
        margin-top: auto;
        text-underline-offset: 0.3em;
        text-decoration-thickness: 1px;
      }
    }
  }
}
</style>
