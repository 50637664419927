<template>
  <div class="logo">
    <div class="logo__background"></div>
    <Lottie
      class="logo__logo"
      ref="logo"
      :options="defaultOptions"
      :height="300"
      @animCreated="handleAnimation"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import { animate } from 'motion'
import Lottie from 'vue-lottie'
import animationData from '../../assets/logo.json'
import mitt from 'mitt'

const eventBus = mitt()
export { eventBus }

export default {
  data() {
    return {
      defaultOptions: { animationData: animationData, loop: false, autoplay: true },
      animation: null,
    }
  },
  methods: {
    handleAnimation(anim) {
      this.animation = anim
      eventBus.emit('logo:playing')
      this.animation.addEventListener('complete', () => this.animationCompleted())
    },
    animationCompleted() {
      eventBus.emit('logo:completed')
      document.documentElement.style.setProperty('--logo-animation-z-index', '1002')
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--logo-animation-z-index', '1007')

    const background = animate(
      '.logo__background',
      { opacity: [1, null, 0] },
      { duration: 6, offset: [0, 0.8, 1], easing: ['ease', 'ease', 'ease'] },
    )

    const logo = animate(
      '.logo__logo',
      {
        left: ['50%', null, '1rem'],
        bottom: ['50%', null, '15%'],
        x: ['-50%', null, '0%'],
        y: ['50%', null, '0%'],
      },
      { duration: 6, offset: [0, 0.8, 1], easing: ['ease', 'ease', 'ease'] },
    ).finished.then(value => {
      if (!value) return
      document.documentElement.style.setProperty('--logo-animation-z-index', '1')
    })

    const body = animate(
      'body',
      {
        overflow: ['hidden', null, 'initial'],
      },
      { duration: 6, offset: [0, 0.8, 1], easing: ['ease', 'ease', 'ease'] },
    )
  },
  components: {
    Lottie,
  },
}
</script>

<style lang="scss">
.logo {
  display: contents;
  z-index: get-layer('logo-animation');

  &__logo {
    z-index: var(--logo-animation-z-index, inherit);
    align-self: end;
    max-height: 300px;
    max-width: 50vw;
    width: fit-content !important;
    height: 50% !important;
    position: absolute;

    @include respond-below('13-tommer') {
      max-height: 200px;
      max-width: 40vw;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      width: 100% !important;
    }

    @include respond-below('tablet') {
      max-height: 150px !important;
      max-width: 52vw !important;
    }
    @include respond-below('phone') {
      max-width: 72vw !important;
    }

    & > svg {
      width: 100% !important;
    }
  }

  &__background {
    pointer-events: none;
    position: fixed;
    inset: 0;
    background: var(--primary);

    z-index: get-layer('logo-animation');
  }
}
</style>
