function getCoverText(title, content) {
  if (title) return `<h1>${title}</h1> ${content}`

  return content
}

export default function () {
  const get = path => this.$path(path, this.$store.state)

  function getSubpageSlug(source) {
    return (
      (
        (get('Root.item.posts') || []).find(
          post => post.customFieldsConfig?.[0]?.source === source,
        ) || {}
      ).slug || '/'
    )
  }

  return [
    {
      component: 'Announcement',

      data: {
        content: get('Page.item.customFields.announcement'),
      },
    },

    {
      component: 'Hero',
      props: {
        images: get('Page.item.customFields.cover'),
        text: get('Page.item.customFields.page-hero.content'),
        links: get('Page.item.customFields.page-hero-buttons'),
        posts: get('Page.item.posts') || []
      }
    },

    ...(get('Page.item.customFields.additional-sections') || []).map((i, index) => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          showResponsivePadding: true,
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          style: index === 0 ? 'scroll-snap-align: start' : null
        },
      }
    }),

    {
      component: 'Section',
      props: {
        width: 'medium',
        style: 'scroll-snap-align: start'
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            columns: 1,
            gap: '2rem'
          },
          blocks: {
            default: [
              {
                component: 'Text',
                props: {
                  style: 'max-width: 1000px; margin-bottom: 3rem',
                  class: 'kpb-section__container'
                },

                data: {
                  content: get('Page.item.customFields.project-portal.content'),
                },
              },
              {
                component: 'Portal',
                variant: 'project',
                props: {
                  hideFilter: true,
                  statusFilter: 'sale',
                  theme: get('Page.item.customFields.project-portal.theme') || 'card',
                  maxItems: parseInt(get('Page.item.customFields.project-portal.max-items')) || 3,
                  layout: get('Page.item.customFields.project-portal.layout'),
                  excluded: get('Page.item.customFields.project-portal.excluded-projects'),
                },
              },
              {
                component: 'Flex',
                props: {
                  justifyContent: 'center'
                },
                blocks: {
                  default: {
                    component: 'Button',
                    condition: get('Page.item.customFields.project-portal.button-label'),
                    props: {
                      class: 'project-portal-button',
                    },
                    data: {
                      label: get('Page.item.customFields.project-portal.button-label'),
                      to: getSubpageSlug('project-portal'),
                      tag: 'router-link',
                      icon: ['far', 'arrow-right']
                    },
                  },
                }
              }
            ],
          }
        }
      },
    },

    ...(get('Page.item.customFields.additional-sections-2') || []).map((i, index) => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          style: index === 0 ? 'scroll-snap-align: start' : null
        },
      }
    }),
  ]
}
