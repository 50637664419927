<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__container container">
        <img :src="logo" alt="" class="footer__logo" />
        <div class="footer__contact">
          <template v-if="companyInfo.email">
            <a class="footer__contact-email" :href="`mailto:${companyInfo.email}`">
              {{ $t('email') }}: {{ companyInfo.email }}
            </a>
            <a class="footer__contact-phone" :href="`tel:${(companyInfo.phone || '').trim()}`">
              {{ $t('phone') }}: {{ companyInfo.phone }}
            </a>
            <p class="footer__contact-orgnumber">
              Org nr {{ companyInfo.orgnumber }}
            </p>
            <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />
          </template>
        </div>

        <div class="footer__menu">
          <template v-for="(item, index) in navigation">
            <ActionComponent v-if="$path('action.name', item) !== $path('name', $route)" class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }" v-bind="item" :key="index" />
            <template v-if="item.items && item.items.length">
              <ActionComponent v-for="(nested, ni) in item.items" class="footer__menu-action footer__menu-action--indent"
                v-bind="nested" :key="index + '_' + ni" />
            </template>
          </template>
        </div>

        <div class="footer__policy footer__clickable">
          <a :href="`/api/legal/privacy/page/${item.id}`" target="_blank">
            {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
          </a>

          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
          <div class="footer__copyright">
            &copy; {{ year }} -

            <span class="footer__bottom">
              {{ $t('pageMadeBy') }}
              <a id="https://www.kvass.no" :href="`https://www.kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
            </span>
          </div>
        </div>
        <FooterContent v-if="customFooterContent.length" :value="customFooterContent" class="footer__custom-content" />
      </div>
    </div>
  </footer>
</template>

<script>
import API from '@/api'
import { GetCustomField } from '@/utils'
import { mapState } from 'vuex'

import SoMeFooter from '@/components/SoMeFooter.vue'
import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    customFooterContent() {
      if (!this.item) return
      let content = GetCustomField('footer-content', this.item.customFields) || []
      if (!content.every(Boolean)) return []
      return content
    },
    companyInfo() {
      if (!this.item) return
      return GetCustomField('company-info', this.item.customFields) || {}
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },

    currentUrl() {
      return encodeURIComponent(window.location.origin)
    },

    logo() {
      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },
  metaInfo() {
    return {
      script: [{ src: 'https://unpkg.com/@kvass/widgets@1.1.2/dist/contact.js', type: 'module' }],
    }
  },

  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  --footer-gap: 0.5rem;

  background-color: var(--primary);
  color: var(--primary-contrast);
  border-top: 1px solid currentColor;

  .container {
    padding-inline: var(--section-padding-inline) !important;
    max-width: calc(1280px + calc(var(--section-padding-inline) * 2));
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  a[href] {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
    }
  }

  &__clickable {

    &>button:hover,
    &>a:hover {
      text-decoration: underline;
    }
  }

  &__logo {
    object-fit: contain;
    object-position: left;
    max-width: 500px;
    min-width: 100px;
    width: 100%;
  }

  &__content {
    padding-block: 4rem;
  }

  &__container {
    display: flex;
    gap: 4rem;
    align-items: start;

    @include respond-below('tablet') {
      gap: 2rem;
      grid-template-columns: 1fr;
      flex-direction: column;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--footer-gap);

    &>* {
      white-space: nowrap;
    }
  }

  --menu-margin: 1rem;

  &__custom-content {
    @include respond-above('tablet') {
      margin-top: var(--menu-margin);
    }
  }

  &__policy {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--footer-gap);
    white-space: nowrap;
  }

  &__menu {
    .elder__navigation-component-icon-right {
      display: none;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--footer-gap);

    .elder__navigation-component {
      padding: 0;
    }

    &-action {
      cursor: pointer;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    margin-block: 0 var(--footer-gap);
  }
}
</style>
