<template>
  <section class="page-hero">
    <div class="page-hero__container page-hero__container--desktop container">
      <div class="page-hero__content">
        <div v-html="text"></div>
        <div class="page-hero__links">
          <router-link :key="index" v-for="(link, index) in links" :to="getLink(link)">{{
            link.content
          }}</router-link>
        </div>
      </div>
      <SliderComponent :value="images" class="page-hero__slider" />
      <Logo />
    </div>

    <div class="page-hero__container page-hero__container--mobile">
      <div class="page-hero__content">
        <div v-html="text" class="page-hero__content-text"></div>
        <SliderComponent :value="images" class="page-hero__slider" />
      </div>
      <div class="page-hero__overlay">
        <Logo />
        <div class="page-hero__links">
          <router-link :key="index" v-for="(link, index) in links" :to="getLink(link)">{{
            link.content
          }}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from '@/components/Page/Logo'
import SliderComponent from '@kvass/media-render/Slider'

export default {
  props: {
    images: Array,
    text: String,
    links: Array,
    posts: Array,
  },
  methods: {
    getLink(link) {
      return link.type === 'contacts' ? { name: 'Contact' } : this.getSubpageSlug(link.type)
    },
    getSubpageSlug(source) {
      return (
        (this.posts.find(post => post.customFieldsConfig?.[0]?.source === source) || {}).slug || '/'
      )
    },
  },
  components: {
    Logo,
    SliderComponent,
  },
}
</script>

<style lang="scss">
.page-hero {
  height: 80vh;
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  position: relative;
  overflow: hidden;

  &__container {
    height: 100%;
    position: absolute;
    inset: 0;
    display: grid;
    gap: 2rem;

    &--desktop {
      grid-template-columns: 1fr 1.25fr;

      @include respond-below('tablet') {
        display: none;
      }

      padding-block: 6rem;

      @media (max-height: 800px) {
        padding-block: 3rem;
      }

      .page-hero__slider {
        width: calc(100vw * (1.25 / (1 + 1.25)));
      }
    }

    &--mobile {
      grid-template-columns: 1fr 3fr;

      @include respond-above('tablet') {
        display: none;
      }

      .page-hero__slider {
        filter: brightness(0.7);
        width: 100%;
      }

      .page-hero__content {
        grid-column: 2 / span 1;
        position: relative;

        &-text {
          text-shadow: 0 2px 2px rgba(black, 0.5);
          padding: 1rem;
          position: absolute;
          z-index: 1;
        }
      }

      .page-hero__links {
        gap: 2rem;
        justify-content: space-between;
        z-index: get-layer('hero-links');
        @include respond-below('tablet') {
          max-width: 550px;
        }
      }

      .page-hero__overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 3rem 1.2rem;
        inset: 0;
      }
    }
  }

  &__slider {
    border-left: 1px solid;
    grid-column: 2 / 3;
    position: absolute;
    left: 0;
  }

  &__links {
    display: flex;
    gap: 1.5rem;
    margin-top: 3rem;
  }
}
</style>
