<template>
  <Loader
    :value="promises.fetch"
    class="portal"
    theme="default"
    :class="[`portal--${theme}`, `portal--masonry-${layoutComp || 'default'}`]"
  >
    <div class="portal__breadcrumbs-wrapper" v-if="hasMany && !hideFilter">
      <DropdownComponent class="portal__breadcrumbs-dropdown" placement="bottom-start">
        <ButtonComponent
          :icon="['far', 'chevron-down']"
          :label="categories.find(element => element.value === current).label"
          @click="hasMany ? () => {} : {}"
        />
        <template v-if="hasMany" #dropdown>
          <ButtonComponent
            v-for="(item, index) in categories"
            :key="index"
            :label="item.label"
            @click="current = item.value"
          />
        </template>
      </DropdownComponent>

      <ButtonComponent
        v-for="(item, index) in categories"
        :key="index"
        :label="item.label"
        class="portal__breadcrumbs-button"
        :class="{ 'portal__breadcrumbs-button--active': current == item.value }"
        @click="current = item.value"
      />
    </div>

    <slot name="top" />

    <transition-group
      v-if="items && items.length"
      tag="div"
      name="list"
      appear
      class="portal__items"
      :class="`portal__items--length-${items.length}`"
    >
      <Card
        v-for="item in filteredItems"
        :key="JSON.stringify(item)"
        :tag="getTag(item)"
        :target="item.url ? '_blank' : '_self'"
        :href="item.url"
        :theme="theme === 'card' ? 'default' : 'flat'"
        :to="to(item.slug)"
        :thumbnail="$path('media.cover', item) ? getImage(item.media.cover) : item.cover.url"
        class="portal__card"
        :class="[
          `portal__card--${variant}`,
          { 'portal__card--disable-nav': !item.url && !item.slug },
        ]"
      >
        <template v-if="!getImage(item.media.cover)" #header>
          <FontAwesomeIcon :icon="['fal', 'house']" size="3x" style="opacity: 0.2" />
        </template>
        <template #default>
          <component :is="Component" :item="item" :theme="theme"> </component>
        </template>
      </Card>
    </transition-group>
  </Loader>
</template>

<script>
import API from '@/api'
import { Capitalize } from '@/utils'
import Card from '@kvass/card'
import { DropdownComponent } from 'vue-elder-dropdown'
import Types from './Types'

function getSortValue(value) {
  switch (value) {
    case 'sale':
    case 'rent':
      return 3
    case 'upcoming':
      return 2
    case 'development':
      return 1
    default:
      return 0
  }
}

function getStatus(item) {
  let type = (item?.metadata?.residentialProperties?.saleType || ['sale'])[0]
  let total = item.stats.total

  //published project with no units
  if (item.isPublished && !total) return 'upcoming'

  switch (type) {
    case 'sale':
      if (item.status) return item.status
      if (!item.stats) return 'sold'
      if (item.isPublished && total === item.stats.sold) return 'sold'
      if (item.isPublished && total && item.stats.upcoming && !item.stats.sale) return 'upcoming'
      if (item.isPublished && total) return 'sale'
      return 'development'

    case 'rent':
      if (item.status) return item.status
      if (!item.stats) return 'rented'
      if (item.isPublished && total === item.stats.rented) return 'rented'
      if (item.isPublished && total && item.stats.upcoming && !item.stats.rent) return 'upcoming'
      if (item.isPublished && total) return 'rent'
      return 'development'

    default:
      return 'development'
  }
}

export default {
  props: {
    variant: {
      type: String,
      default: 'project',
      enums: ['project', 'contact'],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    excluded: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'card',
      enums: ['card', 'masonry', 'tiles', 'thumbnail'],
    },
    disableNav: {
      default: false,
      type: Boolean,
    },

    maxItems: {
      type: Number,
      default: 50,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      enums: ['gallery', 'first-project-span', 'second-project-span'],
    },

    statusFilter: {
      type: String,
      default: 'all',
      validator: val => ['sale', 'upcoming', 'sold', 'all'].includes(val),
    },

    columns: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      promises: {
        fetch: null,
      },
      items: [],
      current: 'all',
    }
  },
  computed: {
    tag() {
      switch (this.variant) {
        case 'project':
          return 'a'
        default:
          return 'div'
      }
    },

    layoutComp() {
      if (this.theme !== 'masonry') return

      if (this.layout) return this.layout

      if (this.items.length === 2) return 'gallery'
      return this.maxItems % 2 == 0 ? 'gallery' : 'second-project-span'
    },
    hasItems() {
      return this.data.content
    },
    Component() {
      return Types[Capitalize(this.variant)]
    },
    filteredItems() {
      return this.items.filter(i => {
        if (this.current === 'all') return true
        switch (this.variant) {
          case 'project':
            return (i.address?.county || i.county) === this.current
          case 'contact':
            return i.categories.includes(this.current)
        }
        return false
      })
    },
    categories() {
      switch (this.variant) {
        case 'project':
          let items = [{ label: this.$tc('all'), value: 'all' }].concat([
            ...new Set(this.items.map(i => i.address?.county || i.county)),
          ])
          return items
            .map(i => {
              if (!i) return {}
              return {
                label: i.label || i,
                value: i.value || i,
              }
            })
            .filter(i => Boolean(i.value))

        case 'contact':
          let contactItems = [{ label: this.$tc('all'), value: 'all' }].concat([
            ...new Set(this.items.reduce((acc, curr) => acc.concat(curr.categories), [])),
          ])

          return contactItems
            .map(i => {
              if (!i) return {}
              return {
                label: this.$tc(i.label || i),
                value: i.value || i,
              }
            })
            .filter(i => Boolean(i.value))

        default:
          return []
      }
    },
    hasMany() {
      return this.categories.length > 1
    },
  },
  methods: {
    getTag(item) {
      if (this.disableNav) return 'div'
      return item.url ? 'a' : 'router-link'
    },
    getImage(value) {
      if (!value || !value.length) return
      let result = value.find(i => {
        if (i.type) return i.type.startsWith('image')
        return i
      })
      if (!result) return
      return result.url
    },

    getData() {
      switch (this.variant) {
        case 'project':
          return API.getPosts()
            .then(posts => {
              let projectSubpages = posts
                .filter(post =>
                  ['project-subpage'].includes((post?.customFieldsConfig[0] || {}).source),
                )
                .map(project => {
                  let status = project.customFields?.metadata?.status
                    ? project.customFields?.metadata?.status.split('.')[1]
                    : 'sold'

                  return {
                    ...project,
                    address: {
                      ...project.customFields.address,
                    },
                    url: project.customFields?.['external-link']?.['add-link']
                      ? project.customFields?.['external-link']?.link
                      : null,
                    name: project.title,
                    metadata: {
                      residentialProperties: {
                        saleType: [project.customFields?.metadata?.saleType || 'sale'],
                        propertyType: ([project.customFields?.metadata?.propertyType] || []).flat().filter(
                          Boolean,
                        ),
                      },
                    },
                    sortValue: project.publishedFrom,
                    status: status,
                  }
                })

              return projectSubpages.sort((a, b) => {
                if (a.sortValue < b.sortValue) return 1
                if (a.sortValue > b.sortValue) return -1
              })
            })
            .then(items => items.filter(i => {
              if(this.statusFilter === 'all') return true
              return i.status === this.statusFilter
            }))
      }
    },

    fetch() {
      if (this.hasItems) return (this.items = this.data.content)

      this.promises.fetch = this.getData().then(res => {
        this.items = res.slice(0, this.maxItems)
      })
    },

    to(slug) {
      if (this.variant == 'project') return `/${slug}`
      return `/${this.$route.params?.slug}/${slug}`
    },
  },
  created() {
    this.fetch()
  },
  components: {
    Card,
    DropdownComponent,
    ...Types,
  },
}
</script>

<style lang="scss">
.portal {
  $breakAt: 850px;

  .elder-button {
    border-radius: 0 !important;

    &__label {
      padding: 0.5em;
    }
  }

  &__breadcrumbs {
    &-wrapper {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 3rem;
    }

    &-dropdown {
      @include respond-above('phone') {
        display: none;
      }

      .elder-button {
        background-color: white;
        width: 100%;
        text-align: left;
      }
    }

    &-button {
      border: unset;
      border-radius: unset;
      padding-inline: 0;
      margin-right: 1rem;

      .elder-button__label {
        padding: 0;
      }

      & > span {
        line-height: 1.2;
      }

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }

      border-bottom: 2px solid transparent;

      &:hover:not(.portal__breadcrumbs-button--active) {
        border-bottom: 2px solid rgba(black, 0.6);
      }

      &--active {
        border-bottom: 2px solid currentColor;
      }

      @include respond-below('phone') {
        display: none;
      }
    }
  }

  &__items {
    position: relative;
    display: grid;
    grid-template-columns: repeat(v-bind(columns), 1fr);
    gap: 2rem;

    @media (max-width: $breakAt) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    &--length {
      &-1 {
        grid-template-columns: 1fr;
      }
    }
  }

  &__card {
    cursor: pointer;

    &--disable-nav {
      cursor: default;

      &:hover {
        transform: none !important;
      }
    }
  }

  .kvass-card {
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s ease;

    &--default {
      box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
    }

    &__header {
      padding: 0;

      @media (max-width: $breakAt) {
        min-height: 250px !important;
      }
    }

    &:hover {
      text-decoration: none;
      transform: scale(1.01);
    }
  }

  &--thumbnail {
    .portal__items {
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      gap: 3rem;
    }

    .kvass-card {
      background: transparent;

      &:hover {
        transform: none;
      }

      &__header {
        min-height: 350px;
        height: 100%;
        position: relative;
        border: none;
      }

      &__content {
        padding: 0;
      }
    }
  }

  &--masonry {
    .portal__items {
      gap: 1rem;
    }

    .kvass-card {
      &__header {
        min-height: 350px;
        height: 100%;
        position: relative;
      }

      &__content {
        padding: 0;
      }
    }

    &-first-project-span {
      .kvass-card:nth-child(1) {
        grid-row-end: span 2;
      }
    }

    &-second-project-span {
      .kvass-card:nth-child(2) {
        grid-row-end: span 2;
      }
    }
  }

  &--card {
    .kvass-card {
      background-color: var(--brand-primary-muted);

      &__content {
        padding: 0;
      }

      &__header {
        min-height: 350px;
        border: none;
      }
    }
  }

  &--tiles {
    .portal__items {
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      @media (max-width: $breakAt) {
        grid-template-columns: 1fr;
      }
    }

    .kvass-card {
      overflow: hidden;

      &__content {
        padding: 2rem;
        min-height: 400px;
      }

      &__header {
        position: absolute;
        width: 100%;
        background: no-repeat;
        height: 100%;
        background-size: cover;
        background-position: 50%;
        transition: transform 0.62s cubic-bezier(0.05, 0.2, 0.1, 1);

        &:hover {
          transform: scale(1.11);
          -webkit-transform: scale(1.11);
        }
      }

      &:hover {
        transform: none;

        .project-card {
          &__content {
            background: rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  .list {
    &-move,
    &-enter-active,
    &-leave-active {
      transition: all 500ms ease;
    }

    &-leave-active {
      position: absolute;
    }

    &-enter {
      transform: scale(0.95);
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
}
</style>
